/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.card-cat{
    height: 175px;
    max-height: 175px;
    overflow-y: hide;
}

.icon-small {
  min-width: 18px !important;
  width: 18px !important;
}

.icon-medium {
    width: 28px !important;
}

.icon-big {
    min-width: 38px !important;
    width: 38px !important;
    height: 38px !important;
}

.icon-big-xl {
    min-width: 38px !important;
    width: 94px !important;
    height: 94px !important;
}

.input-search {
  .mat-form-field-flex {
    min-height: 39px !important;
    height: 39px !important;
    border-radius: 12px !important;
  }
}

.item-data{
    border-bottom: 1px solid #f1f1f1;
    padding: 5px;
}

.item-data:hover{
    background-color: #eee;
}

.boder-active{
    border-bottom: 2px solid;
    border-color: var(--fuse-primary);
    color: var(--fuse-primary);
}

.border-primary{
    border-bottom: 1px solid;
    border-color: var(--fuse-primary);
}

.header-step-box-number{
    border-radius: 50%;
}

.fileover {
    animation: shake 1s;
    animation-iteration-count: infinite;
}

.grid-table-custom{
    grid-template-columns: 50px 1fr 100px 1fr 1fr 1fr 1fr;
}

.mt-neg15 {
    margin-top: -15px !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}

/* Shake animation */
@keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
}

@media only screen and (min-width: 375px){
    .grid-table-custom{
        border: none;
        grid-template-columns: 50px 1fr 150px 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 700px){
    .grid-table-custom{
        border: none;
        grid-template-columns: 50px 1fr 225px 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 450px) {
  .card-cat {
    width: 350px;
    overflow: auto;
    margin: auto;

    .card-card-content {
      min-width: 412px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .card-cat {
    width: 250px;
    overflow: auto;
    margin: auto;

    .card-card-content {
      min-width: 412px;
    }
  }
}

.btnDownload {
  visibility: visible;
  @media (max-width: 1300px) {
      visibility: collapse;
  }
}
.btnDownloadExcel{
    visibility: visible;
}
